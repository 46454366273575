
export const accessTokenMap = "pk.eyJ1IjoibWFzbG92YXkiLCJhIjoiY2t4cHI2bzA0MGNrdjJwbzJpN2k0eDIyYyJ9.L5YHGuIXWBqxG3XMXeQC7Q";
//export const apiURL = "https://localhost:5001/api/Map/";
//export const apiURL = "https://uberu.club/api/Map/";
export const apiURL = "https://api.uberu.club/api/Map/";
export const apiURLTb = "https://app.trashback.net:5001/api/Map/";

export const apiKeyYandex = "aebf5d08-d271-4619-bc9d-328b0b1656c2";
export const geoSuggetKeyYandex = "75af4e8e-68d9-4c78-83c6-edd87b62dd5f";

export const wasteTypes = [
    { value: 'wPet', label: 'ПЭТ-бутылки' },
    { value: 'wPlastic', label: 'Пластик' },
    { value: 'wAluminium', label: 'Алюминиевые банки' },
    { value: 'wGlass', label: 'Стекло' },
    { value: 'wCaps', label: 'Крышечки' },
    { value: 'wPaper', label: 'Бумага/картон' },
    { value: 'wMetall', label: 'Черный металл' },
    { value: 'wMetallColoured', label: 'Цветной металл' },
    { value: 'wWear', label: 'Одежда' },
    { value: 'wBatteries', label: 'Батарейки' },
    { value: 'wLamps', label: 'Лампы' },
    { value: 'wDangerous', label: 'Опасные отходы' },
    { value: 'wAppliances', label: 'Бытовая техника' },
    { value: 'wTetrapak', label: 'Тетрапак' },
    { value: 'wTyres', label: 'Шины' }
  ];
  
export const deviceTypes = [
    { value: 1, label: 'Контейнер' },
    { value: 2, label: 'Пункт приема (с сотрудником)' },
    { value: 3, label: 'Автоматический пункт/фандомат' }
  ];

export const defaultLat = 55.7558;
export const defaultLng = 37.6173;
export const defaultZoom = 12; 

export const emptyDataPoint = {
    Id: null,
    Name: "",
    Lng: 55.7558,
    Lat: 37.6173,
    Description: "",
    Address: "",
    Comment: "",
    CompanyName: "",
    Email: "",
    Phone: "",
    Type: 1,
    Web: "",
    WAluminium: false,
    WAppliances: false,
    WBatteries: false,
    WDangerous: false,
    WGlass: false,
    WLamps: false,
    WMetall: false,
    WWear: false,
    WCaps: false,
    WMetallColoured: false,
    WPet: false,
    WPlastic: false,
    WTetrapak: false,
    WTyres: false,
    WPaper: false
  };
  