import React, { useEffect, useRef, useState } from 'react';

import { FallingLines, ThreeDots	 } from  'react-loader-spinner'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import ModalPoint from './ModalInfo';

import axios from 'axios';
import qs from 'qs';
import ecoOtvetLogo from './icons/ecoOtvet.svg';
import { YMaps, Map, GeolocationControl, SearchControl, useYMaps, Clusterer, Placemark, Circle, ObjectManager } from '@pbe/react-yandex-maps';
import { apiURLTb, accessTokenMap, wasteTypes, deviceTypes, emptyDataPoint, defaultLat, defaultLng, defaultZoom, apiKeyYandex} from "./Constants"

let diffChangeMap = 0.2;
let updating = false;
 
export const AppTb= (props)=> {

  const[isLoading, setIsLoading] = useState(false);
  const[isUpdating, setIsUpdating] = useState(false);
  const[latValue, setLatValue] = useState(defaultLat);
  const[lngValue, setLngValue] = useState(defaultLng);
  const[latPrevious, setLatPrevious] = useState(defaultLat);
  const[lngPrevious, setLngPrevious] = useState(defaultLng);
  const[zoomPrevious, setZoomPrevious] = useState(defaultZoom);
  const[zoomValue, setZoomValue] = useState(defaultZoom);
  const[userLat, setUserLat] = useState(null);
  const[userLng, setUserLng] = useState(null);
  const[isActivePoint, setIsActivePoint] = useState(false);
  const[selectedOption, setSelectedOption] = useState(wasteTypes);
  
  const[wasteTypesSelected, setWasteTypesSelected] = useState(wasteTypes);
  const[deviceTypesSelected, setDeviceTypesSelected] = useState(deviceTypes);
  const[wasteTypesUrl, setWasteTypesUrl] = useState([]);
  const[deviceTypesUrl, setDeviceTypesUrl] = useState([]);
  const[activePointData, setActivePointData] = useState([]);
  const[dataPoints, setDataPoints] = useState([]);
  const[allowEdit, setAllowEdit] = useState(false);
  const[allowAdd, setAllowAdd] = useState(false);
  const[filter, setFilter] = useState(false);
  const[search, setSearch] = useState(false);
  const[modalIsOpen, setModalIsOpen] = useState(false);
  const[modalIsAdd, setModalIsAdd] = useState(false);
  const[modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const[logo, setLogo] = useState(true);
  const[newAppIntegrated, setNewAppIntegrated] = useState(false);
  const[distance, setDistance] = useState(500);
  const[userGeo, setUserGeo] = useState(false);
  const[minZoom, setMinZoom] = useState(defaultZoom-7);
  const mapRef = useRef(null);
  const objectManagerRef = useRef(null);
  
  useEffect(() =>{

    const url = new URL(window.location.href);
    const logo = url.searchParams.get('logo'); 
    const lng = url.searchParams.get('lng'); 
    const lat =url.searchParams.get('lat'); 
    const zoom =url.searchParams.get('zoom'); 
    const wasteTypesSelected =url.searchParams.getAll('wasteTypes'); 
    const deviceTypesSelected =url.searchParams.getAll('deviceTypes');
    const newApp =url.searchParams.get('newapp');
    const filterEnabled =url.searchParams.get('filter');
    const searchEnabled =url.searchParams.get('search');
    const userLatParam =url.searchParams.get('userLat');
    const userLngParam =url.searchParams.get('userLng');
    const distanceParam  =url.searchParams.get('distance');
    const userGeoParam  =url.searchParams.get('userGeo');
    const minZoomParam  =url.searchParams.get('minZoom');
    
    if(minZoomParam != null){
      setMinZoom(minZoomParam)
    }

    if(distanceParam != null){
      setDistance(distanceParam*100)
    }


    if(userGeoParam == "true"){
      setUserGeo(true)
    }

    if(userLatParam != null){
      setUserLat(userLatParam)
    }

    if(userLngParam != null){
      setUserLng(userLngParam)
    }

    if(logo == "false"){
      setLogo(true);
    }

    if(newApp != null){
      setNewAppIntegrated(newApp);
    }

    if(filterEnabled == "true"){
      console.log("Filter enabled")
      setFilter(true);
    }

    if(searchEnabled == "true"){
      setSearch(searchEnabled);
    }

    if(deviceTypesSelected.length >0){
      setDeviceTypesUrl(deviceTypesSelected)
    }

    if(wasteTypesSelected.length >0){
      setWasteTypesUrl(wasteTypesSelected)
    }

    if(url.searchParams.get('edit') == "true")
      setAllowEdit(true)
    if(url.searchParams.get('add') == "true")
      setAllowAdd(true)

   
    if(lng != null){
        setLngValue(lng)
        setLatValue(lat)
        setZoomValue(zoom)
        getData(
          lng, 
          lat, 
          zoom, 
          deviceTypesSelected,
          wasteTypesSelected
          );
    }
    else{
      getData(
        defaultLng, 
        defaultLat, 
        defaultZoom, 
        deviceTypesSelected,
        wasteTypesSelected
        );
    }
  
  }, [])

  const getData = (lng, lat, zoom, devices, wastes) =>{
    console.log("Zoom:" + zoom)
    if (isLoading) return
    setIsLoading(true)
    var params = new URLSearchParams();
    devices.map((i) =>{
      params.append("deviceTypes", i);
    });
    wastes.map((i) =>{
      params.append("wasteTypes", i);
    });
    params.append("zoom", Math.round(zoom),);
    params.append("lat", lat);
    params.append("lng", lng);
    axios({
        method: 'get',
        url: apiURLTb + 'ListPointsGeoJsonAll',
        rejectUnauthorized: false,
        params
    })
    .then(response => {
        console.log(response.data.length)
        setDataPoints(response.data);
          setIsLoading(false);
          updating = false;
    })
    .catch((error) => {
        setIsLoading(false);
        updating = false;
    });
  }


  useEffect(()=>{
    if (
      (Math.abs(lngPrevious-lngValue)>diffChangeMap) ||
      (Math.abs(latPrevious-latValue)>diffChangeMap) ||
      (Math.abs(zoomPrevious-zoomValue)>2)
    )
    {
        getData(lngValue, latValue, zoomValue, deviceTypesUrl, wasteTypesUrl);
        setLngPrevious(lngValue)
        setLatPrevious(latValue)
        setZoomPrevious(zoomValue)
    }
  },[latValue])

 
  const getPointData = (id) => {
      axios({
          method: 'get',
          url: apiURLTb + 'PointData',
          rejectUnauthorized: false,
          params:{
              id: id
          }
        })
        .then(response => {
          setModalIsOpen(true)
          setActivePointData(response.data)
        })
        .catch((error) => {
          setIsLoading(false)
        });
  }

  const callRNPoint = (id) => {
    window.ReactNativeWebView.postMessage('point:' + id);
  }

  const addPoint = () =>{
    setModalIsAdd(true);
    setActivePointData(emptyDataPoint);
    setModalEditIsOpen(true);
  }

  const closeModal = ()=>{
    setModalEditIsOpen(false)
  }

  const fiterData = (wasteTypes, deviceTypes) => {
    setWasteTypesSelected(wasteTypes);
    setDeviceTypesSelected(deviceTypes);
    
   // objectManagerRef.current.setFilter('properties.type == "кафе" || properties.type == "аптека"');
    let dataFilteredByWasteType = 
    wasteTypes.length > 0
    ? dataPoints.filter(feature =>  
      (wasteTypes.filter(e => e.value === 'wPet').length > 0 ? feature.properties.wPet === true : feature.properties.wPet === undefined) ||
      (wasteTypes.filter(e => e.value === 'wPlastic').length > 0 ? feature.properties.wPlastic === true : feature.properties.wPlastic === undefined) ||
      (wasteTypes.filter(e => e.value === 'wAluminium').length > 0 ? feature.properties.wAluminium === true : feature.properties.wAluminium === undefined) ||
      (wasteTypes.filter(e => e.value === 'wGlass').length > 0 ? feature.properties.wGlass === true : feature.properties.wGlass === undefined)||
      (wasteTypes.filter(e => e.value === 'wCaps').length > 0 ? feature.properties.wCaps === true : feature.properties.wCaps === undefined)||
      (wasteTypes.filter(e => e.value === 'wPaper').length > 0 ? feature.properties.wPaper === true : feature.properties.wPaper === undefined)||
      (wasteTypes.filter(e => e.value === 'wMetall').length > 0 ? feature.properties.wMetall === true : feature.properties.wMetall === undefined)||
      (wasteTypes.filter(e => e.value === 'wMetallColoured').length > 0 ? feature.properties.wMetallColoured === true : feature.properties.wMetallColoured === undefined)||
      (wasteTypes.filter(e => e.value === 'wWear').length > 0 ? feature.properties.wWear === true : feature.properties.wWear === undefined)||
      (wasteTypes.filter(e => e.value === 'wBatteries').length > 0 ? feature.properties.wBatteries === true : feature.properties.wBatteries === undefined)||
      (wasteTypes.filter(e => e.value === 'wLamps').length > 0 ? feature.properties.wLamps === true : feature.properties.wLamps === undefined)||
      (wasteTypes.filter(e => e.value === 'wDangerous').length > 0 ? feature.properties.wDangerous === true : feature.properties.wDangerous === undefined)||
      (wasteTypes.filter(e => e.value === 'wAppliances').length > 0 ? feature.properties.wAppliances === true : feature.properties.wAppliances === undefined)||
      (wasteTypes.filter(e => e.value === 'wTetrapak').length > 0 ? feature.properties.wTetrapak === true : feature.properties.wTetrapak === undefined)||
      (wasteTypes.filter(e => e.value === 'wTyres').length > 0 ? feature.properties.wTyres === true : feature.properties.wTyres === undefined)
    )
    :dataPoints;
    
    let dataFilteredByDeviceType = 
    deviceTypes.length > 0
    ? dataFilteredByWasteType.filter(feature =>  
      (deviceTypes.filter(e => e.value === 1).length > 0 ? feature.properties.type === 1 : feature.properties.type === undefined) ||
      (deviceTypes.filter(e => e.value === 2).length > 0 ? feature.properties.type === 2 : feature.properties.type === undefined) ||
      (deviceTypes.filter(e => e.value === 3).length > 0 ? feature.properties.type === 3 : feature.properties.type === undefined) 
    )
    :dataFilteredByWasteType;
    console.log(dataFilteredByDeviceType.length)
    setDataPoints(dataFilteredByDeviceType);
    setIsLoading(false);
    objectManagerRef.current.removeAll();
    objectManagerRef.current.add(dataFilteredByDeviceType)
  };

  return (
      <>
        {filter &&
        <Container>
          <Row>
            <Col xs={12} md={6} style={{zIndex: 1000, marginTop: "5px"}}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Фильтры</Accordion.Header>
                <Accordion.Body>
                  <p >
                    Что сдать:
                  </p>
                  <Select
                    value={wasteTypesSelected}
                    onChange={(f) => fiterData(f, deviceTypesSelected)}
                    options={wasteTypes}
                    isMulti={true}
                  />
                  <p >
                    Тип пункта приема:
                  </p>
                  <Select
                    value={deviceTypesSelected}
                    onChange={(f) =>  fiterData(wasteTypesSelected,f)}
                    options={deviceTypes}
                    isMulti={true}
                  />
                </Accordion.Body>
              </Accordion.Item>
              </Accordion>           
            </Col>
          </Row>
          <Row>
          <Col xs={12} md={12}>
          </Col>
          </Row>
        </Container>}


        <YMaps 
          query={{ apikey: apiKeyYandex}}
          >
          <Map 
            defaultState={{ center: [latValue, lngValue], zoom: zoomValue }} 
            instanceRef={mapRef}
            width="100%" 
            height="100vh"
            options = {{maxZoom: defaultZoom+10, minZoom: minZoom,   suppressMapOpenBlock: true, yandexMapDisablePoiInteractivity: true}}>

                  {userGeo &&
                  <GeolocationControl options={{
                    float: 'right'
                  }}/>}
            
                  {search && <SearchControl options={{ position: {top: 70, right: 20}}} />}


                        {userLat != null 
                        ? <Placemark 
                            geometry={[userLat, userLng]}
                            options={{     
                              iconLayout: 'default#image',
                              iconImageHref: 'images/userLocation.png',
                              iconImageSize: [40, 40]
                            }}     
                            />
                        : <></> }

                        {userLat != null 
                        ?<Circle
                          geometry={[[userLat, userLng], distance]}
                          options={{
                            draggable: false,
                            fillColor: "#11AADA",
                            fillOpacity: 0.1,
                            strokeColor: "#116062",
                            strokeOpacity: 0.5,
                            strokeWidth: 2,
                          }}
                        />
                        :<></>}

                  {dataPoints.length > 0
                  && <ObjectManager
                        instanceRef={objectManagerRef}
                        options={{
                          clusterize: true,
                          minClusterSize: 4
                        }}
                        objects={{
                          openBalloonOnClick: true,
                          iconLayout: 'default#image',
                          iconImageHref: 'images/pointRusPro.png',
                          iconImageSize: [40, 40]
                        }}
                        clusters={{
                          preset: 'islands#blueClusterIcons',
                          groupByCoordinates: false,
                        }}
                        defaultFeatures={dataPoints}
                        modules={[
                        ]}
                        onLoad={(e) => {
                          objectManagerRef.current.objects.events.add('click', (p) => {
                            const objectId = p.get('objectId');
                            let pointId = objectManagerRef.current.objects.getById(objectId).id;
                            console.log(pointId)
                            newAppIntegrated    
                            ? callRNPoint(pointId) 
                            : getPointData(pointId)
                          })
                        }}
                      />
                      }
          </Map>
        </YMaps>

        {modalIsOpen && 
        <ModalPoint
          show={modalIsOpen}
          data={activePointData}
          onHide={() => setModalIsOpen(false)}
        />}
        {logo && <img src={ecoOtvetLogo} alt="Eco otvet logo"  style={{ position: "absolute", zIndex: 1, bottom: "2%", right: "1%", width: "125px"}} /> }
        {isLoading  &&
              <div className='loader-container'>
                <div className='inner'>
                    <ThreeDots color = '#0d90ae' />
                </div>
              </div>
        }
      </>
    )
  }

export default AppTb;