import React, { useEffect, Link } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import MetalLogo from './icons/metal.svg';
import BatteryLogo from './icons/battery.svg';
import PetLogo from './icons/pet.svg';
import LampLogo from './icons/lamp.svg';
import CanLogo from './icons/can.svg';
import PlasticLogo from './icons/plastic.svg';
import HazardLogo from './icons/hazard.svg';
import GoldLogo from './icons/gold.svg';
import GlassLogo from './icons/glass.svg';
import TetrapakLogo from './icons/tetrapak.svg';
import PaperLogo from './icons/paper.svg';
import WashingMachineLogo from './icons/washing-machine.svg';
import CapLogo from './icons/cap.svg';
import TireLogo from './icons/tire.svg';
import WearLogo from './icons/wear.svg';

const  ModalPoint = (props) => {

    useEffect(() => {
        //console.log(props)
    }, [])

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.data.Name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                {props.data.Description}
            </p> 
            {props.data.Address != null
                ?  <p> {props.data.Address}</p>
                :  <></>
            }
            {props.data.Phone != null
                ?  <p>&#x1F4DE; <a href={'tel:'+props.data.Phone}> {props.data.Phone}</a></p>
                :  <></>
            }
            {props.data.Email != null
                ? <p>&#9993; <a href={'mailto:'+props.data.Email}> {props.data.Email}</a></p>
                : ""
              }
            {props.data.Web != null
                ?  <p>&#9782; <a href={props.data.Web.includes('http') ? props.data.Web : ('https://'+ props.data.Web)} target='_blank' > {props.data.Web}</a></p>
                :  <></>
            }
            {props.data.WPet == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={PetLogo} alt='Pet Logo' width='35px' height='35px' title='ПЭТ-бутылки'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            ПЭТ бутылки
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WPlastic == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={PlasticLogo} alt='Plastic Logo' width='35px' height='35px' title='Пластик'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Пластик
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WCaps == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={CapLogo} alt='Caps Logo' width='35px' height='35px' title='Крышечки'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Крышечки
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WAluminium == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={CanLogo} alt='Aluminium Logo' width='35px' height='35px' title='Алюминиевые банки'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Алюминиевые банки
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WGlass == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={GlassLogo} alt='Glass Logo' width='35px' height='35px' title='Стекло'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Стекло
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WPaper == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={PaperLogo} alt='Paper Logo' width='35px' height='35px' title='Бумага и картон'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Бумага и картон
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WMetall == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={MetalLogo} alt='Metal Logo' width='35px' height='35px' title='Черный металл'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Черный металл
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WMetallColoured == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={GoldLogo} alt='Metal Logo' width='35px' height='35px' title='Цветной металл'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Цветной металл
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WBatteries == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={BatteryLogo} alt='Metal Logo' width='35px' height='35px' title='Батарейки'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Батарейки
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WDangerous == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={HazardLogo} alt='Hazard Logo' width='35px' height='35px' title='Опасные отходы'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Опасные отходы
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WAppliances == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={WashingMachineLogo} alt='Hazard Logo' width='35px' height='35px' title='Бытовая техника'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Бытовая техника
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WWear == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={WearLogo} alt='Hazard Logo' width='35px' height='35px' title='Одежда'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                            Одежда
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WLamps == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={LampLogo} alt='Hazard Logo' width='35px' height='35px' title='Лампочки'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                        Лампочки
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WTetrapak == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={TetrapakLogo} alt='Hazard Logo' width='35px' height='35px' title='Тетрапак'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                        Тетрапак
                        </span>
                    </div>
                :  <></>
            }
            {props.data.WTyres == true
                ?  <div style={{width: "15%", display: "inline-block", textAlign: "center", lineHeight: "0.5em", verticalAlign: "top"}}>
                        <img src={TireLogo} alt='Hazard Logo' width='35px' height='35px' title='Шины'/>
                        <br/><br/>
                        <span style={{fontSize: "10px", margin: "2px"}}>
                        Шины
                        </span>
                    </div>
                :  <></>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default ModalPoint;